.page-with-nav {
  .page-with-nav-secondary-nav {
    border-top: solid 1px @grey-300;
    overflow: hidden;
  }

  .page-with-nav-content {
    height: 100%;
    padding: @desktop-gutter;
    max-width: (@desktop-keyline-increment * 14);
  }

  @media @device-medium {
    @subNavWidth: (@desktop-keyline-increment * 3);

    position: relative;

    .page-with-nav-secondary-nav {
      border-top: none;
      position: absolute;
      top: 64px;
      width: @subNavWidth;
    }

    .page-with-nav-content {
      margin-left: @subNavWidth;
      border-left: solid 1px @grey-300;
    }
  }
}

.mui-app-bar .mui-paper-container .mui-paper-button {
  vertical-align: middle !important;
  margin: 0 24px;
  position: relative;
  top: -2px;
}